import React, { useState, useEffect } from 'react';
import { Menu, X, Instagram, Facebook, Phone } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  // Close mobile menu when route changes
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <>
      {/* Main Navigation Bar */}
      <nav className="fixed top-0 w-full bg-black/30 backdrop-blur-sm z-50 border-b border-purple-500/20 lg:hidden">
        <div className="px-4 py-2">
          <div className="flex items-center justify-between h-16">
            <Link to="/" className="flex items-center">
              <img src="/images/logo.png" alt="DJ Chrizz Logo" className="h-14 w-auto" />
            </Link>
            
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-3 rounded-lg text-gray-400 hover:text-white hover:bg-purple-500/20 focus:outline-none transition-colors"
              aria-label="Menu"
            >
              {isOpen ? <X className="h-8 w-8" /> : <Menu className="h-8 w-8" />}
            </button>
          </div>
        </div>
      </nav>

      {/* Desktop Side Navigation */}
      <nav className="fixed inset-y-0 left-0 w-64 bg-black border-r border-purple-500/20 transform transition-transform duration-300 ease-in-out z-40 hidden lg:block">
        <div className="h-full pt-8">
          <Link to="/" className="block px-6 py-4 mb-6">
            <img src="/images/logo.png" alt="DJ Chrizz Logo" className="h-16 w-auto" />
          </Link>
          <div className="px-4 space-y-2">
            <Link to="/" className="block px-4 py-3 text-gray-300 hover:text-white hover:bg-purple-500/20 rounded-lg transition-colors">
              Home
            </Link>
            <Link to="/about" className="block px-4 py-3 text-gray-300 hover:text-white hover:bg-purple-500/20 rounded-lg transition-colors">
              About
            </Link>
            <Link to="/portfolio" className="block px-4 py-3 text-gray-300 hover:text-white hover:bg-purple-500/20 rounded-lg transition-colors">
              Portfolio
            </Link>
            <Link to="/blog" className="block px-4 py-3 text-gray-300 hover:text-white hover:bg-purple-500/20 rounded-lg transition-colors">
              Blog
            </Link>
            <Link to="/booking" className="block px-4 py-3 text-gray-300 hover:text-white hover:bg-purple-500/20 rounded-lg transition-colors">
              Booking
            </Link>
            <Link to="/events" className="block px-4 py-3 text-gray-300 hover:text-white hover:bg-purple-500/20 rounded-lg transition-colors">
              Events
            </Link>
          </div>
          <div className="absolute bottom-8 left-0 right-0 px-6">
            <div className="flex justify-center space-x-6">
              <a href="https://www.instagram.com/djchrizz84/" target="_blank" rel="noopener noreferrer" className="hover:scale-110 transition-transform">
                <Instagram className="h-5 w-5 text-gray-300 hover:text-purple-500" />
              </a>
              <a href="https://www.facebook.com/christian.ewerling.1" target="_blank" rel="noopener noreferrer" className="hover:scale-110 transition-transform">
                <Facebook className="h-5 w-5 text-gray-300 hover:text-purple-500" />
              </a>
              <a href="tel:015123735195" className="hover:scale-110 transition-transform">
                <Phone className="h-5 w-5 text-gray-300 hover:text-purple-500" />
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Menu Overlay */}
      <div 
        className={`lg:hidden fixed inset-0 bg-black/95 backdrop-blur-sm z-30 transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
        style={{ top: '72px' }}
      >
        <div className="px-4 py-6 space-y-3">
          <Link 
            to="/" 
            className="block px-6 py-5 text-gray-300 hover:text-white hover:bg-purple-500/20 rounded-lg transition-colors text-xl font-medium"
            onClick={() => setIsOpen(false)}
          >
            Home
          </Link>
          <Link 
            to="/about" 
            className="block px-6 py-5 text-gray-300 hover:text-white hover:bg-purple-500/20 rounded-lg transition-colors text-xl font-medium"
            onClick={() => setIsOpen(false)}
          >
            About
          </Link>
          <Link 
            to="/portfolio" 
            className="block px-6 py-5 text-gray-300 hover:text-white hover:bg-purple-500/20 rounded-lg transition-colors text-xl font-medium"
            onClick={() => setIsOpen(false)}
          >
            Portfolio
          </Link>
          <Link 
            to="/blog" 
            className="block px-6 py-5 text-gray-300 hover:text-white hover:bg-purple-500/20 rounded-lg transition-colors text-xl font-medium"
            onClick={() => setIsOpen(false)}
          >
            Blog
          </Link>
          <Link 
            to="/booking" 
            className="block px-6 py-5 text-gray-300 hover:text-white hover:bg-purple-500/20 rounded-lg transition-colors text-xl font-medium"
            onClick={() => setIsOpen(false)}
          >
            Booking
          </Link>
          <Link 
            to="/events" 
            className="block px-6 py-5 text-gray-300 hover:text-white hover:bg-purple-500/20 rounded-lg transition-colors text-xl font-medium"
            onClick={() => setIsOpen(false)}
          >
            Events
          </Link>
          
          <div className="pt-10 mt-10 border-t border-purple-500/20">
            <div className="flex justify-center space-x-6">
              <a href="https://www.instagram.com/djchrizz84/" target="_blank" rel="noopener noreferrer" className="hover:scale-110 transition-transform">
                <Instagram className="h-8 w-8 text-gray-300 hover:text-purple-500" />
              </a>
              <a href="https://www.facebook.com/christian.ewerling.1" target="_blank" rel="noopener noreferrer" className="hover:scale-110 transition-transform">
                <Facebook className="h-8 w-8 text-gray-300 hover:text-purple-500" />
              </a>
              <a href="tel:015123735195" className="hover:scale-110 transition-transform">
                <Phone className="h-8 w-8 text-gray-300 hover:text-purple-500" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Calendar, Mail, Phone, User, MapPin, Music, Building2, Users, Clock, Wallet, AlertCircle } from 'lucide-react';
import { useScrollTop } from '../hooks/useScrollTop';
import emailjs from '@emailjs/browser';
import { bookingSchema, type BookingFormData } from '../utils/bookingSchema';

// Initialize EmailJS with your Public Key
emailjs.init("Z-2f3tW8eqGmTJlwY");

const BookingForm = () => {
  useScrollTop();
  const formRef = useRef<HTMLFormElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [formData, setFormData] = useState<BookingFormData>({
    name: '',
    email: '',
    phone: '',
    company: '',
    eventType: '',
    date: '',
    startTime: '',
    endTime: '',
    budget: '',
    guests: 50,
    message: '',
  });

  const validateForm = () => {
    try {
      bookingSchema.parse(formData);
      return true;
    } catch (error) {
      console.error('Validation error:', error);
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus('idle');

    try {
      // Use the form element itself as the EmailJS submission method, which is more reliable
      if (formRef.current) {
        const result = await emailjs.sendForm(
          'service_6dv9wok',
          'template_y7lxnxa',
          formRef.current,
          'Z-2f3tW8eqGmTJlwY'
        );
        
        console.log('Success:', result.text);
        setSubmitStatus('success');
        setFormData({
          name: '',
          email: '',
          phone: '',
          company: '',
          eventType: '',
          date: '',
          startTime: '',
          endTime: '',
          budget: '',
          guests: 50,
          message: '',
        });
      }
    } catch (error) {
      console.error('Failed to send email:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    // Map EmailJS field names to our state properties
    const fieldMapping: Record<string, string> = {
      'user_name': 'name',
      'user_email': 'email',
      'event_type': 'eventType',
      'start_time': 'startTime',
      'end_time': 'endTime',
      'to_email': 'info@djchrizz.de'
    };
    
    const stateField = fieldMapping[name] || name;
    
    setFormData(prev => ({
      ...prev,
      [stateField]: stateField === 'guests' ? parseInt(value) || 0 : value
    }));
  };

  return (
    <div className="pt-16 lg:pt-0">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
      >
        <h1 className="text-3xl md:text-4xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
          Booking Anfrage
        </h1>

        <div className="grid md:grid-cols-3 gap-8">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            className="space-y-6 md:col-span-1"
          >
            <div className="bg-black/50 backdrop-blur-sm rounded-lg p-6 border border-purple-500/20">
              <h2 className="text-2xl font-semibold mb-4">Kontakt</h2>
              <p className="text-gray-300">
                Professioneller DJ-Service für Ihre Veranstaltung
              </p>
              <ul className="mt-4 space-y-2">
                <li className="flex items-center text-gray-300">
                  <Phone className="w-4 h-4 mr-2 text-purple-500" />
                  015123735195
                </li>
                <li className="flex items-center text-gray-300">
                  <Mail className="w-4 h-4 mr-2 text-purple-500" />
                  info@djchrizz.de
                </li>
              </ul>
            </div>

            <div className="bg-black/50 backdrop-blur-sm rounded-lg p-6 border border-purple-500/20">
              <h2 className="text-2xl font-semibold mb-4">Services</h2>
              <ul className="space-y-3 text-gray-300">
                <li className="flex items-start">
                  <Calendar className="w-5 h-5 text-purple-500 mt-1 mr-3" />
                  <span>Hochzeiten & Events</span>
                </li>
                <li className="flex items-start">
                  <Music className="w-5 h-5 text-purple-500 mt-1 mr-3" />
                  <span>Professionelles Equipment</span>
                </li>
                <li className="flex items-start">
                  <Users className="w-5 h-5 text-purple-500 mt-1 mr-3" />
                  <span>Über 22 Jahre Erfahrung</span>
                </li>
              </ul>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="md:col-span-2"
          >
            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className="space-y-6"
              data-netlify="true"
            >              
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-300">
                    Name*
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="text"
                      name="user_name"
                      id="name"
                      required
                      value={formData.name}
                      onChange={handleInputChange}
                      className="block w-full bg-black/30 border border-purple-500/20 rounded-md py-2 px-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    />
                    <User className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                  </div>
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                    E-Mail*
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="email"
                      name="user_email"
                      id="email"
                      required
                      value={formData.email}
                      onChange={handleInputChange}
                      className="block w-full bg-black/30 border border-purple-500/20 rounded-md py-2 px-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    />
                    <Mail className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                  </div>
                </div>

                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-300">
                    Telefon*
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      required
                      value={formData.phone}
                      onChange={handleInputChange}
                      className="block w-full bg-black/30 border border-purple-500/20 rounded-md py-2 px-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    />
                    <Phone className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                  </div>
                </div>

                <div>
                  <label htmlFor="company" className="block text-sm font-medium text-gray-300">
                    Firma
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      value={formData.company}
                      onChange={handleInputChange}
                      className="block w-full bg-black/30 border border-purple-500/20 rounded-md py-2 px-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    />
                    <Building2 className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>

              <div>
                <label htmlFor="eventType" className="block text-sm font-medium text-gray-300">
                  Art der Veranstaltung *
                </label>
                <div className="mt-1 relative">
                  <input
                    type="text"
                    name="event_type"
                    id="eventType"
                    required
                    value={formData.eventType}
                    onChange={handleInputChange}
                    className="block w-full bg-black/30 border border-purple-500/20 rounded-md py-2 px-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                  <Music className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="date" className="block text-sm font-medium text-gray-300">
                    Datum *
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="date"
                      name="date"
                      id="date"
                      required
                      value={formData.date}
                      onChange={handleInputChange}
                      className="block w-full bg-black/30 border border-purple-500/20 rounded-md py-2 px-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    />
                    <Calendar className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                  </div>
                </div>

                <div>
                  <label htmlFor="budget" className="block text-sm font-medium text-gray-300">
                    Budget *
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="text"
                      name="budget"
                      id="budget"
                      required
                      value={formData.budget}
                      onChange={handleInputChange}
                      className="block w-full bg-black/30 border border-purple-500/20 rounded-md py-2 px-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    />
                    <Wallet className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                  </div>
                </div>

                <div>
                  <label htmlFor="startTime" className="block text-sm font-medium text-gray-300">
                    Startzeit *
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="time"
                      name="start_time"
                      id="startTime"
                      required
                      value={formData.startTime}
                      onChange={handleInputChange}
                      className="block w-full bg-black/30 border border-purple-500/20 rounded-md py-2 px-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    />
                    <Clock className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                  </div>
                </div>

                <div>
                  <label htmlFor="endTime" className="block text-sm font-medium text-gray-300">
                    Endzeit *
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="time"
                      name="end_time"
                      id="endTime"
                      required
                      value={formData.endTime}
                      onChange={handleInputChange}
                      className="block w-full bg-black/30 border border-purple-500/20 rounded-md py-2 px-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    />
                    <Clock className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-300">
                  Nachricht *
                </label>
                <div className="mt-1">
                  <textarea
                    name="message"
                    id="message"
                    rows={6}
                    required
                    value={formData.message || ''}
                    onChange={handleInputChange}
                    className="block w-full bg-black/30 border border-purple-500/20 rounded-md py-2 px-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                </div>
              </div>

              {submitStatus === 'error' && (
                <div className="rounded-md bg-red-900/50 p-4">
                  <div className="flex">
                    <AlertCircle className="h-5 w-5 text-red-400" />
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-300">
                        Fehler beim Senden
                      </h3>
                      <div className="mt-2 text-sm text-red-200">
                        <p>
                          Bitte versuchen Sie es später erneut oder kontaktieren Sie mich direkt.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {submitStatus === 'success' && (
                <div className="rounded-md bg-green-900/50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-green-300">
                        Anfrage erfolgreich gesendet
                      </h3>
                      <div className="mt-2 text-sm text-green-200">
                        <p>
                          Ich werde mich schnellstmöglich bei Ihnen melden.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white glow-button ${
                    isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105'
                  }`}
                >
                  {isSubmitting ? 'Wird gesendet...' : 'Anfrage senden'}
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default BookingForm;